import React, { useState, useEffect } from "react";
import "./App.css";
import { jsPDF } from "jspdf";
import { font } from "./inter.js";
import { font2 } from "./playfair.js";
import { font3 } from "./playfairitalic.js";
import { font4 } from "./interbold.js";
import coverred from "./assets/coverred.png";
import coveryellow from "./assets/coveryellow.png";
import coverblack from "./assets/coverblack.png";
import coverblue from "./assets/coverblue.png";
import covergreen from "./assets/covergreen.png";
import coverlightgreen from "./assets/coverlightgreen.png";
import loadinggif from './loadinggif.gif'
import axios from 'axios'
import heic2any from 'heic2any';
import { useNavigate } from "react-router-dom";


interface QuestionTemplate {
  id: string;
  name: string;
}

interface Blank {
  photoUrl?: string;
  text?: string;
  questionToPrecede: string;
}

interface Photo {
  photoUrl: string;
  date: string;
  description: string;
  questionToPrecede: string;
  hideDate: boolean;
  hideDescription: boolean;
  questionTxt: string;
}

interface User {
  questionAnswerPairs: QuestionAnswerPair[];
  _id: any;
  username: string;
  questionTemplates: QuestionTemplate[];
  bookName: string;
  authorName: string;
  partnerName: string;
  coverUrl: string;
  photos: Photo[];
  blanks: Blank[];
}

interface QuestionAnswerPair {
  chapter: any;
  question: string;
  answer: string;
  number: string;
}

interface UserListItem {
  username: string;
  _id: any;
  password: string;
  questionTemplates: QuestionTemplate[];
}

interface Template {
  _id: any;
  name: string;
}

interface Answers {
  [key: string]: string;
}

interface Versions {
  [key: string]: Answers;
}


function App() {
  const [userData, setUserData] = useState<User | null>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [userList, setUserList] = useState<UserListItem[]>([]);
  const [alltemplates, setTemplates] = useState<Template[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedPhoto, setSelectedPhoto] = useState<Photo | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');

  const [originalAnswers, setOriginalAnswers] = useState<Answers>({});
  const [correctedAnswers, setCorrectedAnswers] = useState<Answers>({});
  const [showAnswers, setShowAnswers] = useState(false);
  const [showBlank, setShowBlank] = useState(false);

  const [partnerInCover, setPartnerInCover] = useState(false);

  const [blankPhotoFile, setBlankPhotoFile] = useState<File | null>(null);
  const [questionToPrecede, setQuestionToPrecede]= useState('')

  const [prompt, setPrompt]= useState('Исправь все грамматические и пунктуационные ошибки в тексте, сохраняя оригинальную структуру и смысл. Верни ТОЛЬКО текст. Не перефразируй текст, просто исправь ошибки. Если ошибок нет, верни текст без изменений. Текст: ')
 

  const [versions, setVersions] = useState<Versions>({});
  const [currentVersion, setCurrentVersion] = useState<string | null>(null);  

  const [successfulUpdates, setSuccessfulUpdates] = useState(0);


  const [usernameCreate, setUsernameCreate] = useState('');
  const [passwordCreate, setPasswordCreate] = useState('');
  const [templateIdCreate, setTemplateIdCreate] = useState('');
  const [isAuth, setIsAuth] = useState(true);

  const navigate = useNavigate()

  const authToken = localStorage.getItem('auth');
  useEffect(() => {
  if(authToken !== null && authToken === "true") {
    setIsAuth(true);
  } else {
    setIsAuth(false);
    navigate('/login')
  }
  console.log(authToken)
  }, [authToken])

  const handleSubmit = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    axios.post('https://api.comabooks.org/user/create', {
      userName: usernameCreate,
      password: passwordCreate,
      templateId: templateIdCreate
    })
    .then(response => {
      alert('User created successfully!');
    })
    .catch(error => console.error('Failed to create user:', error));
  };
  
  useEffect(() => {
    // Load versions from local storage when the component mounts
    const savedVersions = JSON.parse(localStorage.getItem(`versions-${userData?.username}`) || "{}");
    setVersions(savedVersions);
  }, [userData]);

  const handleSaveVersion = () => {
    const versionKey = Date.now().toString();
    const newVersions = {
      ...versions,
      [versionKey]: correctedAnswers
    };
    setVersions(newVersions);
    localStorage.setItem(`versions-${userData?.username}`, JSON.stringify(newVersions));
    setCurrentVersion(versionKey);
  };

  const handleSaveVersionAuto = (corrected: any) => {
    const versionKey = Date.now().toString();
    const newVersions = {
      ...versions,
      [versionKey]: corrected
    };
    setVersions(newVersions);
    localStorage.setItem(`versions-${userData?.username}`, JSON.stringify(newVersions));
    setCurrentVersion(versionKey);
  };

  const handleLoadVersion = (versionKey: string) => {
    const versionData = versions[versionKey];
    if (versionData) {
      setCorrectedAnswers(versionData);
      setCurrentVersion(versionKey);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const fetchData = async (username = search) => {
    setIsLoading(true);
    try {
      const userResponse = await fetch(`https://api.comabooks.org/user/${username.trim()}`);
      if (!userResponse.ok) {
        throw new Error(`HTTP error! status: ${userResponse.status}`);
      }
      const user = await userResponse.json();
  
      let coverData = {};
      let photosData = [];
      let questionAnswerPairs: {
        question: any; answer: any; chapter: string | undefined;
      }[] = [];
  
      // Fetch cover data
      try {
        const coverResponse = await fetch(`https://api.comabooks.org/cover/admin/${user._id}`);
        coverData = coverResponse.ok ? await coverResponse.json() : {};
      } catch (error) {
        console.error(`Error fetching cover data for user ${user._id}:`, error);
      }
  
      // Fetch photo data
      try {
        const photosResponse = await fetch(`https://api.comabooks.org/photos/${user._id}`);
        const photosArray = photosResponse.ok ? await photosResponse.json() : [];
        const uniquePhotos = new Set();
        
        photosData = photosArray.filter((photo: { photoUrl: any; date: any; description: any; hideDate: boolean; hideDescription: boolean; questionTxt: string; questionToPrecede: string }) => {
          console.log('Photo:', photo);
          photo.questionToPrecede = photo.questionTxt || '';
          const photoSignature = `${photo.photoUrl}-`;
          if (!uniquePhotos.has(photoSignature)) {
            uniquePhotos.add(photoSignature);
            return true;
          }
          return false;
        });
      } catch (error) {
        console.error(`Error fetching photos for user ${user._id}:`, error);
      }
  
      // Fetch question and answer pairs
      if (user.questionTemplates.length > 0) {
        try {
          const questionsResponse = await fetch(`https://api.comabooks.org/questions/${user.questionTemplates[0].id}`);
          const questions = await questionsResponse.json();
          if (!Array.isArray(questions)) {
            console.error("Expected questions to be an array, received:", questions);
            return;
          }
    
          const answersResponse = await fetch(`https://api.comabooks.org/answers/user/${user._id}/template/${user.questionTemplates[0].id}`);
          const answers = await answersResponse.json();

          const getChapter = (index: number) => {
            if (user.questionTemplates[0].id === '65dda647dc778d94d0928969') {
              if (index <= 83) return "Мы";
              else if (index <= 129) return "Она";
              else return "Что если..";
            } else if (user.questionTemplates[0].id === '660e02b2ce933929f288c10c') {
              if (index <= 82) return "Мы";
              else if (index <= 128) return "Он";
              else return "Что если..";
            } else if (user.questionTemplates[0].id === '65ec32bffc68f8ec35c47182') {
              if (index <= 82) return "Мы";
              else if (index <= 128) return "Он";
              else return "Что если..";
            } else if (user.questionTemplates[0].id === '66163e630f78aa6b9f522b09') {
              if (index <= 89) return "Біз";
              else if (index <= 137) return "Ол";
              else return "Егер де..";
            } else if (user.questionTemplates[0].id === '660e255e4d408cc2c1155322') {
              if (index <= 82) return "Мы";
              else if (index <= 128) return "Он";
              else return "Что если..";
            } else if(user.questionTemplates[0].id === "66137cae18ab6072f1cea1df") {
              if (index <= 82) return "We";
              else if (index <= 128) return "He";
              else return "What if..";
            }
          };
    
          questionAnswerPairs = questions.map((q, index) => {
              const answer = answers.find((a: { questionId: any; }) => a.questionId === q._id);
              return {
                question: q.question,
                answer: answer ? answer.answer : "No answer",
                chapter: getChapter(index + 1),
                number: index + 1
              };
            }).filter(pair => pair.answer && pair.answer !== "-" && pair.answer !== " " && pair.answer !== "No answer");
            const original: Answers = {};
            questionAnswerPairs.forEach(async pair => {
              original[pair.question] = pair.answer;
            });
            setOriginalAnswers(original);

        } catch (error) {
          console.error(`Error fetching question-answer pairs for user ${user._id}:`, error);
        }
      }
  
      const userWithDetails = {
        ...user,
        ...coverData,
        photos: Array.isArray(photosData) ? photosData : [],
        questionAnswerPairs,
        blanks: []
      };
  
      setUserData(userWithDetails);

    } catch (error) {
      if (error instanceof Error) {
        console.error("Fetch error:", error.message);
      } else {
        console.error("An unexpected error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };

  console.log(userData)

  


  const autocorrect = async (user: User) => {
    setIsLoading(true)
    setSuccessfulUpdates(0);
    const corrected: Answers = {};
    for (let index = 0; index < user.questionAnswerPairs.length; ++index) {
    const pair = user.questionAnswerPairs[index];
      corrected[pair.question] = await checkAndCorrectGrammar(pair.answer);
      setSuccessfulUpdates(prev => prev + 1);
    };
    setCorrectedAnswers(corrected);
    setIsLoading(false)
    setSuccessfulUpdates(0);
    setShowAnswers(true); 
    handleSaveVersionAuto(corrected);
  };

  const manual = async (user: User) => {
    const corrected: Answers = {};
    user.questionAnswerPairs.forEach(async pair => {
      corrected[pair.question] = pair.answer;
    });
    setCorrectedAnswers(corrected);
    setShowAnswers(true); 
  };

  const handleCorrectionChange = (question: string, correctedText: string) => {
    setCorrectedAnswers(prev => ({
      ...prev,
      [question]: correctedText
    }));

    // Update the current version or create a new one if none exists
    setVersions(prevVersions => {
      const updatedVersions = { ...prevVersions };

      // If there is a current version selected, update it; otherwise, create a new version
      const versionKey = currentVersion || Date.now().toString();

      updatedVersions[versionKey] = {
        ...updatedVersions[versionKey], // Safely handle undefined by spreading possibly undefined and overriding with the new data
        [question]: correctedText
      };

      // Save updated versions to local storage
      localStorage.setItem(`versions-${userData?.username}`, JSON.stringify(updatedVersions));

      // Set current version if it wasn't set (i.e., if it was the first correction and no version was selected)
      if (!currentVersion) {
        setCurrentVersion(versionKey);
      }

      return updatedVersions;
    });
};


  const saveCorrections = () => {
    setUserData(prev => {
      if (prev === null) return null;
  
      const updatedPairs = prev.questionAnswerPairs.map(pair => ({
        ...pair,
        answer: correctedAnswers[pair.question] ?? pair.answer // Use the corrected answer, or default to the original if not available
      }));
  
      return {...prev, questionAnswerPairs: updatedPairs}; // Return a complete User object
    });
  
    setShowAnswers(false);
    setCurrentVersion(null);
  };
  
  


  useEffect(() => {
    const fetchUserList = async () => {
      try {
      const userResponse = await fetch("https://api.comabooks.org/userlist");
      if (!userResponse.ok) {
        throw new Error(`HTTP error! status: ${userResponse.status}`);
      }
      const users = await userResponse.json();
  
      setUserList(users);
      console.log(users);
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error("Fetch error:", error.message);
      } else {
        console.error("An unexpected error occurred");
      }
    }
    };
    
    if(authToken !== null && authToken === "true") {
      fetchUserList();
    } else {
      return;
    }
  }, []);
  

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
      const templateResponse = await fetch("https://api.comabooks.org/templates");
      if (!templateResponse.ok) {
        throw new Error(`HTTP error! status: ${templateResponse.status}`);
      }
      const templates = await templateResponse.json();
  
      setTemplates(templates);
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error("Fetch error:", error.message);
      } else {
        console.error("An unexpected error occurred");
      }
    }
    };
  
    fetchTemplates();
  }, []);
  

  async function getCoverBase64(url: string): Promise<string> {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Cache-Control": "no-cache",
        },
      });
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (typeof reader.result === "string") {
            resolve(reader.result);
          } else {
            reject("Expected a string in FileReader result");
          }
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });

    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async function getImageBase64(url: string): Promise<string> {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Cache-Control": "no-cache",
      },
    });
    const blob = await response.blob();
  
    // Check if the blob is a HEIC file
    if (blob.type === 'image/heic') {
      const convertedBlob = await heic2any({ blob, toType: "image/jpeg" });
      const singleBlob = Array.isArray(convertedBlob) ? convertedBlob[0] : convertedBlob;
      const convertedUrl = URL.createObjectURL(singleBlob);
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          if (ctx === null) {
            reject(new Error("Failed to get canvas context"));
            return;
          }
          const targetWidth = 1024;
          const targetHeight = 1603;
          canvas.width = targetWidth;
          canvas.height = targetHeight;
          const scale = Math.max(canvas.width / img.width, canvas.height / img.height);
          const x = (canvas.width / 2) - (img.width / 2) * scale;
          const y = (canvas.height / 2) - (img.height / 2) * scale;
  
          ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
  
          // Add 50% black overlay
          ctx.fillStyle = 'rgba(0, 0, 0, 0.4)';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
  
          resolve(canvas.toDataURL());
        };
        img.onerror = () => reject(new Error("Image loading error"));
        img.src = convertedUrl;
      });
    } else {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          if (ctx === null) {
            reject(new Error("Failed to get canvas context"));
            return;
          }
          const targetWidth = 1024;
          const targetHeight = 1603;
          canvas.width = targetWidth;
          canvas.height = targetHeight;
          const scale = Math.max(canvas.width / img.width, canvas.height / img.height);
          const x = (canvas.width / 2) - (img.width / 2) * scale;
          const y = (canvas.height / 2) - (img.height / 2) * scale;
  
          ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
  
          // Add 50% black overlay
          ctx.fillStyle = 'rgba(0, 0, 0, 0.4)';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
  
          resolve(canvas.toDataURL());
        };
        img.onerror = () => reject(new Error("Image loading error"));
        img.src = URL.createObjectURL(blob);
      });
    }
  }



async function getBlankImage(url: string): Promise<string> {
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Cache-Control": "no-cache",
    },
  });
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (ctx === null) {
        reject(new Error("Failed to get canvas context"));
        return;
      }
      // Set fixed dimensions:
      const targetWidth = 1068;
      const targetHeight = 1603;

      canvas.width = targetWidth;
      canvas.height = targetHeight;
      
      // Calculate scaling factors to maintain aspect ratio:
      const scaleX = targetWidth / img.width;
      const scaleY = targetHeight / img.height;
      const scale = Math.min(scaleX, scaleY);  // Use the smaller scale factor to ensure the entire image fits

      // Calculate centered positions:
      const x = (targetWidth - img.width * scale) / 2;
      const y = (targetHeight - img.height * scale) / 2;

      ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
      resolve(canvas.toDataURL());
    };
    img.onerror = () => reject(new Error("Image loading error"));
    img.src = URL.createObjectURL(blob);
  });
}


const OPENAI_API_KEY = 'sk-proj-ZJ6kCV1uVyrkp31FetLhT3BlbkFJPsyDPROgupMuYwbemoJh';

async function checkAndCorrectGrammar(text: string) {
  const response = await fetch('https://api.openai.com/v1/chat/completions', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${OPENAI_API_KEY}`
    },
    body: JSON.stringify({
        model: 'gpt-4-turbo-preview',
        messages: [{
            role: "user",
            content: `${prompt} ${text}`
        }],
        max_tokens: 1000,
    })
  });
  const data = await response.json();
  console.log(data);
  if (data.error) {
      console.error(`Error from OpenAI: ${data.error.message}`);
      return "Error: Could not get a response from the API.";
  }
  if (!data.choices || data.choices.length === 0 || !data.choices[0].message) {
      console.error("No valid response found.");
      return "Error: No answer could be retrieved.";
  }
  return data.choices[0].message.content.trim();
}




  const generatePDF = async (user: User) => {
    setIsLoading(true);
    const doc = new jsPDF({
      orientation: "p",
      unit: "px",
      format: [1068, 1603],
    }); 

    let pageNumber = 1; 

    const Inter = font;
    doc.addFileToVFS("Inter.ttf", Inter);
    doc.addFont("Inter.ttf", "Inter", "normal");
    doc.setFont("Inter");
    const Playfair = font2;
    doc.addFileToVFS("Playfair.ttf", Playfair);
    doc.addFont("Playfair.ttf", "Playfair", "normal");
    doc.setFont("Playfair");
    const PlayfairItalic = font3;
    doc.addFileToVFS("PlayfairItalic.ttf", PlayfairItalic);
    doc.addFont("PlayfairItalic.ttf", "PlayfairItalic", "normal");
    const InterBold = font4;
    doc.addFileToVFS("InterBold.ttf", InterBold);
    doc.addFont("InterBold.ttf", "InterBold", "normal");

  // Add title page
  doc.setFontSize(38);
  doc.text(user.authorName, 534, 170, { align: "center" });
  doc.setFontSize(60);
  const paddingLeft = 90; 
  const paddingRight = 90;
  const textWidth = 1068 - paddingLeft - paddingRight;
  doc.text(user.bookName, 534, 801, { align: "center", maxWidth: textWidth });
  doc.setFontSize(38);
  doc.text('Comabooks Publishing House', 534, 1390, { align: "center" });
  doc.text('2024', 534, 1450, { align: "center" });
  doc.addPage();
  pageNumber++;

  // Add another blank page
  doc.addPage();
  pageNumber++;
  
  doc.setFont("Playfair");
  doc.setFontSize(38);
  if (user.questionTemplates[0].id === "66163e630f78aa6b9f522b09") {
    doc.text('Мазмұны', 534, 170, { align: "center" });
  } else if (user.questionTemplates[0].id === "66137cae18ab6072f1cea1df") {
    doc.text('Content', 534, 170, { align: "center" });
  }
  else {
  doc.text('Cодержание', 534, 170, { align: "center" });
  }
  doc.setFont("Playfair");
  doc.setFontSize(38);

  doc.addPage();
  pageNumber++;

  // Add the last blank page
  doc.addPage();
  pageNumber++;

  // Add another title page
  doc.setFont("Playfair");
  doc.setFontSize(32);
  if (user.questionTemplates[0].id === "66163e630f78aa6b9f522b09") {
    doc.text('Алғы сөз', 534, 731, { align: "center" });
  }  else if (user.questionTemplates[0].id === "66137cae18ab6072f1cea1df") {
    doc.text('Introduction', 534, 731, { align: "center" });
    }
   else {
  doc.text('Предисловие', 534, 731, { align: "center" });
  }

  doc.setFontSize(110);
  if (user.questionTemplates[0].id === "66163e630f78aa6b9f522b09") {
    doc.text('Баспадан', 534, 831, { align: "center" });
  } else if (user.questionTemplates[0].id === "66137cae18ab6072f1cea1df") {
    doc.text('Publisher', 534, 831, { align: "center" });
    }
  else {
  doc.text('От издательства', 534, 831, { align: "center" });
  }
  doc.addPage();
  pageNumber++;

  // Add the last blank page
  doc.addPage();
  pageNumber++;

  // Start adding content from here
  
  console.log(user.questionTemplates[0].id)
  if (user.questionTemplates[0].id === "65dda647dc778d94d0928969" || user.questionTemplates[0].id === "660e255e4d408cc2c1155322") {
  doc.setFont("Playfair");
  doc.setFontSize(38);
  doc.text('Приветствуем вас!', 150, 412);
  doc.text('Если вы читаете эти строки, значит, ваш близкий', 150, 472);
  doc.text('человек решился на неожиданный шаг, посвятив', 150, 508);
  doc.text('вам собственную книгу. Этот труд посвящен вам,', 150, 544);
  doc.text(`${(user.partnerName).split(' ')[0]}.`, 150, 580);
  doc.text(`${(user.authorName).split(' ')[0]} вложил в эту книгу свое сердце, время и`, 150, 640);
  doc.text('душу, тщательно отбирая каждое слово, чтобы', 150, 676);
  doc.text('передать всю глубину своих чувств к вам.', 150, 712);
  doc.text('Эта книга - это больше чем просто набор слов. В', 150, 772);
  doc.text('ней вы найдете эпизоды смеха, радости,', 150, 808);
  doc.text('испытаний и побед, сплетенные воедино, как', 150, 844);
  doc.text('вечное напоминание о любви, которая не знает', 150, 880);
  doc.text('границ. Приятного чтения!', 150, 916);
  doc.text('С любовью,', 150, 976);
  doc.text('Comabooks Publishing House', 150, 1015);
  addFooter(doc, pageNumber, user.authorName, user.bookName);
  doc.addPage();
  pageNumber++;
} else if (user.questionTemplates[0].id === "660e02b2ce933929f288c10c" || user.questionTemplates[0].id === "65ec32bffc68f8ec35c47182") {
    doc.setFont("Playfair");
  doc.setFontSize(38);
  doc.text('Приветствуем вас!', 150, 412);
  doc.text('Если вы читаете эти строки, значит, ваш близкий', 150, 472);
  doc.text('человек решился на неожиданный шаг, посвятив', 150, 508);
  doc.text('вам собственную книгу. Этот труд посвящен вам,', 150, 544);
  doc.text(`${(user.partnerName).split(' ')[0]}.`, 150, 580);
  doc.text(`${(user.authorName).split(' ')[0]} вложила в эту книгу свое сердце, время и`, 150, 640);
  doc.text('душу, тщательно отбирая каждое слово, чтобы', 150, 676);
  doc.text('передать всю глубину своих чувств к вам.', 150, 712);
  doc.text('Эта книга - это больше чем просто набор слов. В', 150, 772);
  doc.text('ней вы найдете эпизоды смеха, радости,', 150, 808);
  doc.text('испытаний и побед, сплетенные воедино, как', 150, 844);
  doc.text('вечное напоминание о любви, которая не знает', 150, 880);
  doc.text('границ. Приятного чтения!', 150, 916);
  doc.text('С любовью,', 150, 976);
  doc.text('Comabooks Publishing House', 150, 1015);
  addFooter(doc, pageNumber, user.authorName, user.bookName);
  doc.addPage();
  pageNumber++;
  } else if (user.questionTemplates[0].id == "66163e630f78aa6b9f522b09") {
    doc.setFont("Playfair");
  doc.setFontSize(38);
  doc.text('Сәлеметсіз бе!', 150, 412);
  doc.text('Егер сіз бұл кітапті оқитын болсаңыз, онда сіздің', 150, 472);
  doc.text('жақын адамыңыз күтпеген қадамға барып, өз', 150, 508);
  doc.text(`қолымен кітап жазып шықты деген сөз. ${(user.partnerName).split(' ')[0]},`, 150, 544);
  doc.text('бұл туынды толықтай сізге арналған.', 150, 580);
  doc.text(`${(user.authorName).split(' ')[0]} бұл кітапқа өзінің жүрегін, уақыты мен`, 150, 640);
  doc.text('жан дүниесін салып, сізге деген сезімінің', 150, 676);
  doc.text('тереңдігін жеткізу үшін әрбір сөзді мұқият таңдаған.', 150, 712);
  doc.text('Бұл кітап жай сөздер жиынтығы емес. Мұнда сіз', 150, 772);
  doc.text('араңыздағы шексіз махаббаты дәлелдегіш күлкі,', 150, 808);
  doc.text('қуаныш, қиындықтар мен жеңіс сәттерін', 150, 844);
  doc.text('таба аласыз. Оқудан ләззат алыңыз!', 150, 880);
  doc.text('Махаббатпен,', 150, 940);
  doc.text('Comabooks Publishing House', 150, 976);
  addFooter(doc, pageNumber, user.authorName, user.bookName);
  doc.addPage();
  pageNumber++;
  }

   // Add another blank page
   doc.addPage();
   pageNumber++;

  let currentChapter = null;
  let currentChapterNumber = 1;
  

  const addChapterPage = (chapter: string | string[]) => {
    // doc.addPage();
    doc.setFont("Playfair");
    doc.setFontSize(40);
    if (user.questionTemplates[0].id == "66163e630f78aa6b9f522b09") {
      doc.text(`${currentChapterNumber} Бөлім`, 534, 741, { align: "center" });
    } else if (user.questionTemplates[0].id == "66137cae18ab6072f1cea1df") {
      doc.text(`Chapter ${currentChapterNumber}`, 534, 741, { align: "center" });
    } else {
      doc.text(`Глава ${currentChapterNumber}`, 534, 741, { align: "center" });
    }
    doc.setFontSize(110);
    doc.text(chapter, 534, 831, { align: "center" });
    pageNumber++;
    currentChapterNumber++;
    doc.addPage();
  };


  const updateTOC = (chapter: string | string[]) => {
    doc.setPage(3);
    doc.setFontSize(38);
    doc.setFont("Playfair");
    if(chapter === "Мы") {
    doc.text(`Глава 1: ${chapter}......................................................................${pageNumber}`, 150, 300);
    } else if(chapter === "Она" || chapter === "Он") {
      doc.text(`Глава 2: ${chapter}..................................................................${pageNumber}`, 150, 340);
    } else if(chapter === "Біз") {
      doc.text(`1 Бөлім: ${chapter}....................................................................${pageNumber}`, 150, 300);
    } else if(chapter === "Ол") {
      doc.text(`2 Бөлім: ${chapter}..................................................................${pageNumber}`, 150, 340);
    } else if(chapter === "Егер де..") {
      doc.text(`3 Бөлім: ${chapter}......................................................${pageNumber}`, 150, 380);
    } else if(chapter === "We") {
      doc.text(`Chapter 1 ${chapter}..................................................................${pageNumber}`, 150, 300);
    } else if(chapter === "He" || chapter === "She") {
      doc.text(`Chapter 3: ${chapter}..................................................................${pageNumber}`, 150, 340);
    } else if(chapter === "What if..") {
      doc.text(`Chapter 3: ${chapter}..................................................................${pageNumber}`, 150, 380);
    } else {
      doc.text(`Глава 3: ${chapter}......................................................${pageNumber}`, 150, 380);
    }

    doc.setPage(pageNumber)
  };



    for (let index = 0; index < user.questionAnswerPairs.length; ++index) {
      const pair = user.questionAnswerPairs[index];

      if (index > 0) {
        doc.addPage();
        pageNumber++;
      }

      if (user.questionTemplates[0].id === "65dda647dc778d94d0928969"
      || user.questionTemplates[0].id === "660e02b2ce933929f288c10c"
      || user.questionTemplates[0].id === "66163e630f78aa6b9f522b09"
      || user.questionTemplates[0].id === "660e255e4d408cc2c1155322"
      || user.questionTemplates[0].id === "65ec32bffc68f8ec35c47182"
      || user.questionTemplates[0].id === "66137cae18ab6072f1cea1df") {
      if (pair.chapter !== currentChapter) {
        updateTOC(pair.chapter)
        addChapterPage(pair.chapter);
        currentChapter = pair.chapter;
      }
      }



      doc.setDrawColor(0);
      doc.setFillColor(255, 255, 255);
      doc.setTextColor(0, 0, 0)
      doc.rect(140, 155, 1068 - 140 * 2, 1603 - 155 * 2, "F");
      addFooter(doc, pageNumber, user.authorName, user.bookName);

      doc.setFont("Playfair");
      doc.setFontSize(36);
      const questionX = 140 + 20;
      let questionY = 195;
      
      const answerX = questionX;
      let answerY = questionY + 100;

      doc.text(pair.question, questionX, questionY, {
        maxWidth: 1068 - 140 * 2 - 20,
      });

      const estimatedLines = Math.ceil(
        doc.getTextWidth(pair.question) / (1068 - 140 * 2 - 20)
      );
      answerY += estimatedLines * 24;
      
      doc.setFont("Playfair");
      doc.setFontSize(80);
      const lineHeight = 48 * 1.6;
      const pageHeight = 1603 - 205;
      const contentWidth = 1068 - 140 * 2 - 20;
      const bottomMargin = 100;
      
      
      const answerLines = doc.splitTextToSize(pair.answer, contentWidth);
      answerLines.forEach((line: string | string[]) => {
        if (Array.isArray(line)) {
          line = line.join(" ");
        }
        if (answerY + lineHeight > pageHeight - bottomMargin) {
          doc.addPage();
          answerY = 155 + 40;
          pageNumber++;
          addFooter(doc, pageNumber, user.authorName, user.bookName);
        }
        if (hasEmoji(line)) {
          const canvas = drawTextOnCanvas(line, 200, "Playfair");
          if (canvas) {
            const imgData = canvas.toDataURL('image/png');
            doc.addImage(imgData, 'PNG', answerX, answerY - 55, 724, 77);
          }
        } else {
          doc.setFont("Playfair");
            doc.setFontSize(80);
            doc.text(line, answerX, answerY);
        }

        answerY += lineHeight;
    });

    const photosToPlace = user.photos.filter(photo => photo.questionToPrecede === pair.question);

    for (const photo of photosToPlace) {

      const imgData = await getImageBase64(photo.photoUrl);
      doc.addPage();

      if(photo.hideDescription && !photo.hideDate) {
      doc.addImage(imgData, 'JPEG', 0, 0, 1068, 1603, undefined, 'FAST');
      doc.setFont("Playfair");
      doc.setFontSize(42);
      const formattedDate = formatDate(photo.date);
      doc.setTextColor(255, 255, 255)
      doc.text(formattedDate, 534, 851, { align: "center" });
      doc.setTextColor(0, 0, 0) }
      
      else if(!photo.hideDescription && photo.hideDate) {
        doc.addImage(imgData, 'JPEG', 0, 0, 1068, 1603, undefined, 'FAST');
        const paddingLeft = 200;
        const paddingRight = 200;
        const textWidth = 1068 - paddingLeft - paddingRight;
        doc.setFont("PlayfairItalic");
        doc.setFontSize(42);
        doc.setTextColor(255, 255, 255)
        doc.text(photo.description, 534, 851, {
          align: "center",
          maxWidth: textWidth
        });
        doc.setTextColor(0, 0, 0)
      }

      else if(photo.hideDescription && photo.hideDate) {
        doc.addImage(imgData, 'JPEG', 0, 0, 1068, 1603, undefined, 'FAST');
      }

      else if (!photo.hideDescription && !photo.hideDate) {
        doc.addImage(imgData, 'JPEG', 0, 0, 1068, 1603, undefined, 'FAST');
      doc.setFont("Playfair");
      doc.setFontSize(42);
      const formattedDate = formatDate(photo.date);
      doc.setTextColor(255, 255, 255)
      doc.text(formattedDate, 534, 851, { align: "center" }); 
      doc.setTextColor(0, 0, 0)
      const paddingLeft = 200;
      const paddingRight = 200;
      const textWidth = 1068 - paddingLeft - paddingRight;
      doc.setFont("PlayfairItalic");
      doc.setFontSize(42);
      doc.setTextColor(255, 255, 255)
      doc.text(photo.description, 534, 931, {
        align: "center",
        maxWidth: textWidth
      });
      doc.setTextColor(0, 0, 0)
      }
      pageNumber++;
      doc.setTextColor(255, 255, 255)
      addFooter(doc, pageNumber, user.authorName, user.bookName);
    }




    const blanksToPlace = user.blanks.filter(blank => blank.questionToPrecede === pair.question);
    for (const blank of blanksToPlace) {
      doc.addPage()
      if (blank.photoUrl) {
        const imgData = await getBlankImage(blank.photoUrl);
        doc.addImage(imgData, 'JPEG', 0, 0, 1068, 1603, undefined, 'FAST');
      }
      pageNumber++;
    }
      addFooter(doc, pageNumber, user.authorName, user.bookName);
    }
    
      doc.addPage();
      doc.setFont("Playfair");
      doc.setFontSize(100);
      if (user.questionTemplates[0].id === "66163e630f78aa6b9f522b09") {
        doc.text('Соңы', 534, 801, { align: "center" });
      } else {
      doc.text('Конец', 534, 801, { align: "center", maxWidth: 844 });
      }
      pageNumber++;

      doc.addPage();
      pageNumber++;



    doc.save(`${user.authorName}.pdf`);
    setIsLoading(false);
  };

  function hasEmoji(str: string) {

    const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;
    return emojiRegex.test(str);
  }
  
  function drawTextOnCanvas(text: string, fontSize: number, fontFamily: string): HTMLCanvasElement | undefined {
    const canvas = document.createElement('canvas');
    canvas.width = 2392;
    canvas.height = 231;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.font = `${fontSize}px ${fontFamily}`;
      ctx.fillText(text, 0, 170);
      return canvas;
    }
    return undefined;
  }

  const generateCoverPDF = async (user: {
    partnerName: string; coverUrl: any; bookName: string; authorName: string }) => {
    setIsLoading(true);
  
    let coverImg;
    switch (user.coverUrl) {
      case '/static/media/red front.fee4324978b31c9e9958.png':
        coverImg = coverred;
        break;
      case '/static/media/yellow front.fee4324978b31c9e9958.png':
        coverImg = coveryellow;
        break;
      case '/static/media/black front.fee4324978b31c9e9958.png':
        coverImg = coverblack;
        break;
      case '/static/media/blue front.fee4324978b31c9e9958.png':
        coverImg = coverblue;
        break;
      case '/static/media/green front.fee4324978b31c9e9958.png':
        coverImg = covergreen;
        break;
      case '/static/media/lightgreen front.fee4324978b31c9e9958.png':
        coverImg = coverlightgreen;
        break;

        case 'https://gtaxi.s3.eu-central-1.amazonaws.com/comabooks/blue+front+(2).png':
          coverImg = coverblue;
          break;

          case 'https://gtaxi.s3.eu-central-1.amazonaws.com/comabooks/black+fornt+(2).png':
            coverImg = coverblack;
            break;

            case 'https://gtaxi.s3.eu-central-1.amazonaws.com/comabooks/green+front+(2).png':
              coverImg = covergreen;
              break;

              case 'https://gtaxi.s3.eu-central-1.amazonaws.com/comabooks/light+green+front+(2).png':
                coverImg = coverlightgreen;
                break;

                case 'https://gtaxi.s3.eu-central-1.amazonaws.com/comabooks/red+front+(3).png':
                  coverImg = coverred;
                  break;

                  case 'https://gtaxi.s3.eu-central-1.amazonaws.com/comabooks/yellow+front+1.png':
                    coverImg = coveryellow;
                    break;
      default:
        coverImg = null;
    }
  
    if (!coverImg) {
      console.error('Cover image not found.');
      setIsLoading(false);
      return;
    }
  
    const imgData = await getCoverBase64(coverImg);
  
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'px',
      format: [1198, 900]
    });

    const Inter = font;
    doc.addFileToVFS("Inter.ttf", Inter);
    doc.addFont("Inter.ttf", "Inter", "normal");
    doc.setFont("Inter");
    const Playfair = font2;
    doc.addFileToVFS("Playfair.ttf", Playfair);
    doc.addFont("Playfair.ttf", "Playfair", "normal");
    doc.setFont("Playfair");
    
    // doc.addImage(imgData, 'JPEG', 0, 0, 1198, 900);
    doc.addImage(imgData, 'JPEG', 0, 0, 1198, 900, undefined, 'FAST'); 
    
    doc.setFontSize(36);
    doc.setFont("Playfair");
    // Center horizontally and respect the left and right margins
    doc.text(user.bookName, 910, 375, {
      align: 'center',
      maxWidth: 250
    });

    doc.setFontSize(21);
    doc.setFont("Inter");
    // Center horizontally and respect the left and right margins
    doc.text(user.authorName, 910, 552, {
      align: 'center',
      maxWidth: 250
    });

    doc.save(`${user.authorName} cover.pdf`);
    setPartnerInCover(false);
    setIsLoading(false);
  };

  const generateCoverWPartner = async (user: {
    partnerName: string; coverUrl: any; bookName: string; authorName: string }) => {
    setIsLoading(true);
  
    let coverImg;
    switch (user.coverUrl) {
      case '/static/media/red front.fee4324978b31c9e9958.png':
        coverImg = coverred;
        break;
      case '/static/media/yellow front.fee4324978b31c9e9958.png':
        coverImg = coveryellow;
        break;
      case '/static/media/black front.fee4324978b31c9e9958.png':
        coverImg = coverblack;
        break;
      case '/static/media/blue front.fee4324978b31c9e9958.png':
        coverImg = coverblue;
        break;
      case '/static/media/green front.fee4324978b31c9e9958.png':
        coverImg = covergreen;
        break;
      case '/static/media/lightgreen front.fee4324978b31c9e9958.png':
        coverImg = coverlightgreen;
        break;

        case 'https://gtaxi.s3.eu-central-1.amazonaws.com/comabooks/blue+front+(2).png':
          coverImg = coverblue;
          break;

          case 'https://gtaxi.s3.eu-central-1.amazonaws.com/comabooks/black+fornt+(2).png':
            coverImg = coverblack;
            break;

            case 'https://gtaxi.s3.eu-central-1.amazonaws.com/comabooks/green+front+(2).png':
              coverImg = covergreen;
              break;

              case 'https://gtaxi.s3.eu-central-1.amazonaws.com/comabooks/light+green+front+(2).png':
                coverImg = coverlightgreen;
                break;

                case 'https://gtaxi.s3.eu-central-1.amazonaws.com/comabooks/red+front+(3).png':
                  coverImg = coverred;
                  break;

                  case 'https://gtaxi.s3.eu-central-1.amazonaws.com/comabooks/yellow+front+1.png':
                    coverImg = coveryellow;
                    break;
      default:
        coverImg = null;
    }

    if (!coverImg) {
      console.error('Cover image not found.');
      setIsLoading(false);
      return;
    }
  
    const imgData = await getCoverBase64(coverImg);
  
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'px',
      format: [1198, 900]
    });

    const Inter = font;
    doc.addFileToVFS("Inter.ttf", Inter);
    doc.addFont("Inter.ttf", "Inter", "normal");
    doc.setFont("Inter");
    const Playfair = font2;
    doc.addFileToVFS("Playfair.ttf", Playfair);
    doc.addFont("Playfair.ttf", "Playfair", "normal");
    doc.setFont("Playfair");
    
    // doc.addImage(imgData, 'JPEG', 0, 0, 1198, 900);
    doc.addImage(imgData, 'JPEG', 0, 0, 1198, 900, undefined, 'FAST'); 
    
    doc.setFontSize(36);
    doc.setFont("Playfair");
    // Center horizontally and respect the left and right margins
    doc.text(user.bookName, 910, 375, {
      align: 'center',
      maxWidth: 250
    });

    doc.setFontSize(21);
    doc.setFont("Inter");
    // Center horizontally and respect the left and right margins
    doc.text(user.authorName, 910, 552, {
      align: 'center',
      maxWidth: 250
    });

    doc.text(user.partnerName, 910, 570, {
      align: 'center',
      maxWidth: 250
    });

    doc.save(`${user.authorName} cover.pdf`);
    setPartnerInCover(false);
    setIsLoading(false);
  };
  

  function formatDate(dateString: string | number | Date) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // JavaScript months are zero-indexed
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  }  

  function addFooter(
    doc: jsPDF,
    pageNumber: number,
    authorName: string,
    bookName: string
  ) {
    doc.setFont("Playfair");
    doc.setFontSize(36);
    const pageWidth = doc.internal.pageSize.width;
    const leftMargin = 160;
    const rightMargin = 160;
    const bottomY = 1450;

    const isEvenPage = pageNumber % 2 === 0;
    const authorOrBookName = isEvenPage ? authorName : bookName;
    let footerSize;
    if (authorName.length > 25) {
    footerSize = isEvenPage ? 36 : 30;
    } else {
    footerSize = isEvenPage ? 36 : 36;
    }

    const authorOrBookNameWidth = doc.getTextWidth(authorOrBookName);
    const pageNumberWidth = doc.getTextWidth(`${pageNumber}`);

    const authorOrBookNameX = isEvenPage
      ? pageWidth - rightMargin - authorOrBookNameWidth
      : leftMargin;
    const pageNumberX = isEvenPage
      ? leftMargin
      : pageWidth - rightMargin - pageNumberWidth;

    doc.setFontSize(footerSize);
    doc.text(authorOrBookName, authorOrBookNameX, bottomY);
    doc.setFontSize(36);
    doc.text(`${pageNumber}`, pageNumberX, bottomY);
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (userData) {
      setUserData({
        ...userData,
        [name]: value
      });
    }
  };
  //   if (userData) {
  //     const updatedPhotos = userData.photos.map(photo => {
  //       const initialQuestion = userData.questionAnswerPairs.find(pair => pair.question === photo.questionTxt);
  //       if (initialQuestion) {
  //         return {
  //           ...photo,
  //           questionToPrecede: initialQuestion.question
  //         };
  //       }
  //       return photo;
  //     });
  
  //     setUserData(prev => {
  //       if (!prev) return null;
  //       return {
  //         ...prev,
  //         photos: updatedPhotos
  //       };
  //     });
  //   }
  // }, [userData]);


  const handleImageSelect = (user: User, photo: Photo) => {
    setSelectedUser(user);
    setSelectedPhoto(photo);
    setShowPopup(true);
  };
  

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setBlankPhotoFile(file);
  };
  

  const addBlankPage = async (questionToPrecede: string) => {
    if (!blankPhotoFile) {
      alert("Please select a photo file.");
      return;
    }
  
    const reader = new FileReader();
    reader.readAsDataURL(blankPhotoFile);
    reader.onload = () => {
      const photoUrl = reader.result; // This could be null
      if (typeof photoUrl === 'string') { // Ensure it's a string
        if (userData) {
          const newBlank = { questionToPrecede, photoUrl };
          const updatedBlanks = [...userData.blanks, newBlank];
          setUserData({
            ...userData,
            blanks: updatedBlanks
          });
          setShowBlank(false);
          setBlankPhotoFile(null); // Clear the file after adding
        }
      }
    };
    reader.onerror = (error) => {
      console.error('Error converting image to Base64:', error);
    };
  };
  

  const updatePhotoPosition = (question: any) => {
    if (selectedPhoto && userData) {
      // Update the photos array within userData
      const updatedPhotos = userData.photos.map(photo => 
        photo.photoUrl === selectedPhoto.photoUrl ? { ...photo, questionToPrecede: question } : photo
      );
  
      // Set the updated userData with the new photos array
      setUserData({
        ...userData,
        photos: updatedPhotos
      });

      setShowPopup(false);
      setSelectedPhoto(null);
    }
  };
  

  const exportDataToJson = (userData: { username: any; questionAnswerPairs: any; }) => {
    const fileName = `${userData.username}_QA_Pairs.json`;
    const json = JSON.stringify(userData.questionAnswerPairs, null, 2);
    const blob = new Blob([json], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
  
    // Create a link and trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link); // Required for Firefox
    link.click();
    document.body.removeChild(link); // Clean up
    URL.revokeObjectURL(url); // Free up memory by releasing the URL
  };
  


  return (
    <div className="app-container">
    {isLoading ? (
      <>
      <img className="loading" src={loadinggif} alt="Loading..." />
      <div className="counter">{successfulUpdates}/{userData?.questionAnswerPairs.length} is done</div>
      </>
    ) : (
      <>
      <div className="add-user">
        {/* <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={usernameCreate}
          onChange={(e) => setUsernameCreate(e.target.value)}
          placeholder="Username"
        />
        <input
          type="text"
          value={passwordCreate}
          onChange={(e) => setPasswordCreate(e.target.value)}
          placeholder="Password"
        />
        <select
        className="select-templateid"
        value={templateIdCreate}
        onChange={(e) => setTemplateIdCreate(e.target.value)}
      >
        <option value="">Select Template</option>
        {alltemplates?.map((id, idx) => (
          <option key={idx} value={id._id}>{id.name}</option>
        ))}
      </select>
        <button type="submit" className="add-user-button">Cоздать аккаунт</button>
        </form> */}

        <div className="search-input">
        <input
          type="text"
          value={search}
          onChange={handleSearchChange}
          placeholder="Search by username"
        />
        <button onClick={() => fetchData(search)} className="search-button">Найти</button>
      </div>
      </div>

        {userData && (
          <div className="app-grid">
            <div className="app-user">
              <div className="app-user-username">{userData.username}, {userData.questionAnswerPairs.length} вопросов</div>
              <div className="app-user-template">
                {userData.questionTemplates.map((t) => t.name).join(", ")}
              </div>
              <input
                required
                className="app-user-input"
                type="text"
                name="bookName"
                value={userData.bookName || ""}
                placeholder="Book name"
                onChange={handleInputChange}
              />
              <input
                required
                className="app-user-input"
                type="text"
                name="authorName"
                value={userData.authorName || ""}
                placeholder="Author name"
                onChange={handleInputChange}
              />
              <input
                required
                className="app-user-input"
                type="text"
                name="partnerName"
                value={userData.partnerName || ""}
                placeholder="Partner name"
                onChange={handleInputChange}
              />
              <textarea
                className="prompt"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="Type prompt"
              />

              <div className="images-grid">
                {userData.photos.length > 0 &&
                  userData.photos.map((photo, index) => (
                    <button
                      key={index}
                      onClick={() => handleImageSelect(userData, photo)}
                      className="user-image-button"
                    >
                      <img
                        src={photo.photoUrl}
                        alt={photo.description}
                        className="user-image"
                        loading="lazy"
                        style={{ borderBottom: photo.questionToPrecede || photo.questionToPrecede ? '5px solid #7FFFD4' : 'none' }}
                      />
                    </button>
                  ))}
              </div>
              <div className="images-grid">
                {userData.blanks.length > 0 && 
                  userData.blanks.map((blank, index) => (
                    <>
                    <button
                      key={index}
                      className="user-blank-button"
                    >
                    </button>
                    </>
                  ))}
                <button className="user-blank-button" onClick={() => setShowBlank(true)}>+</button>
              </div>

              {!showAnswers &&
              <>
              <div className="correct-button">
              <button onClick={() => autocorrect(userData)}>Autocorrect</button>
              <button onClick={() => manual(userData)}>Manual</button>
              </div>

              <button
                      onClick={() => generatePDF(userData)}
                      className="app-user-pdf"
                >
                      Generate PDF
              </button>

              <button 
              onClick={() => {
                if (userData) {
                  generateCoverPDF(userData);
                }
              }} 
              className="generate-cover-button">
              Generate Cover
            </button>   
              
              <button 
              onClick={() => {
                if (userData) {
                  generateCoverWPartner(userData);
                }
              }} 
              className="generate-cover-button">
              Generate Cover w partner
            </button>       
            <button onClick={() => exportDataToJson(userData)} className="export-json-button">
                        Export Data to JSON
              </button></>
            }

            </div>
          </div>
        )}

        {showAnswers && userData && (
          <div className="answer-correction">
            <div className="versions">
              {Object.keys(versions).map((versionKey: string) => (
                <button
                  key={versionKey}
                  onClick={() => handleLoadVersion(versionKey)}
                  style={{
                    border: currentVersion === versionKey ? '2px solid blue' : '1px solid grey',
                    padding: '5px 10px',
                    margin: '5px',
                    cursor: 'pointer',
                    backgroundColor: currentVersion === versionKey ? '#f0f0f0' : 'white'
                  }}
                >
                  Version {new Date(parseInt(versionKey)).toLocaleString()}
                </button>
              ))}
            </div>


                          {userData.questionAnswerPairs.map(pair => (
                            <div className="old-new-answers" key={pair.question}>
                              <div className="old">
                              {originalAnswers[pair.question].split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                  {line}
                                  <br />
                                </React.Fragment>
                              ))}
                              </div>
                              <textarea
                                className="new"
                                value={correctedAnswers[pair.question]}
                                onChange={(e) => handleCorrectionChange(pair.question, e.target.value)}
                              />
                            </div>
                          ))}
                        <button className="done-button" onClick={saveCorrections}>Done</button>
          </div>
        )}

        {!showAnswers &&
        <>
        <div className="userlistcount">{userList.length} users</div><div className="userlist">
          {userList.map((user, index) => (
        <button key={index} onClick={() => fetchData(user.username)} className="userlist-one">
          {user.username}
        </button>
          ))}
        </div>
        </>
        }
      </>
    )}
{showBlank &&
  <div className="popup">
    <div className="popup-content">
      <select
        className="popup-question"
        value={questionToPrecede}
        onChange={(e) => setQuestionToPrecede(e.target.value)}
      >
        <option value="">Select Question to Place After</option>
        {userData?.questionAnswerPairs.map((pair, idx) => (
          <option key={idx} value={pair.question}>{pair.number}. {pair.question}</option>
        ))}
      </select>
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
      />
      <button onClick={() => addBlankPage(questionToPrecede)}>
        Add Blank
      </button>
      <button onClick={() => setShowBlank(false)}>Close</button>
    </div>
  </div>
}


    {showPopup && selectedPhoto && selectedUser && (
      <div className="popup">
        <div className="popup-content">
          <img
            className="popup-image"
            src={selectedPhoto.photoUrl}
            alt={selectedPhoto.description}
          />
          <input
            placeholder="Date"
            value={formatDate(selectedPhoto.date)}
            readOnly
          />
          <textarea
            placeholder="Description"
            value={selectedPhoto.description}
            readOnly
          />
          <select
            className="popup-question"
            value={selectedPhoto.questionToPrecede || ''}
            onChange={(e) => updatePhotoPosition(e.target.value)}
          >
            <option value="">Select Question to Place After</option>
            {selectedUser.questionAnswerPairs.map((pair, idx) => (
              <option key={idx} value={pair.question}>{pair.number}. {pair.question}</option>
            ))}
          </select>

          <button
            onClick={() => {
              setShowPopup(false);
              setSelectedPhoto(null);
            }}
          >
            Close
          </button>
        </div>
      </div>
    )}
  </div>
  
  );
}

export default App;